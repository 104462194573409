<template>
    <div class="terms-container">
        <div class="section-title-container">
            <div class="title">TERMS OF SERVICE</div>
        </div>
        <p>
            <span class="title">IMPORTANT—PLEASE READ CAREFULLY:</span> This Terms of Service Agreement ("Agreement") is a legal contract between You and Sway Medical, Inc., ("Sway") that covers your use of the Sway Application, which includes computer software and, as applicable, associated media, databases, printed materials, “online” or electronic documentation and Sway’s website (collectively the "Software").
        </p>
        <p>
            BY INSTALLING OR OTHERWISE USING THE SOFTWARE, YOU AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT AND ANY ADDITIONAL TERMS FOUND ON
            <a href="https://www.swaymedical.com/terms">swaymedical.com/terms</a> and <a href="https://www.swaymedical.com/privacy-policy">swaymedical.com/privacy-policy</a>, INCLUDING THE WARRANTY DISCLAIMERS, LIMITATIONS OF LIABILITY, AND TERMINATION PROVISIONS BELOW.
        </p>
        <p>
            IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT AND/OR THE ADDITIONAL TERMS, DO NOT INSTALL OR USE THE SOFTWARE, AND EXIT NOW.
        </p>
        <p>
            IF YOU ARE NOT WILLING OR UNABLE TO ACCEPT THESE TERMS AND CONDITIONS, YOU SHOULD CLICK THE “DISAGREE” BUTTON BELOW. IF YOU DO NOT ACCEPT THESE TERMS YOU WILL NOT BE GRANTED ACCESS TO USE THE SOFTWARE OR SWAY’S WEB SITE (<a href="https://www.swaymedical.com">swaymedical.com</a>, or the "SITE").
        </p>
        <p>
            USE OF THE SOFTWARE WITHOUT SIGNIFYING YOUR AGREEMENT TO THESE TERMS IN THE MANNER INDICATED ABOVE IS STRICTLY PROHIBITED.
        </p>
        <p>
            In the event that there is any conflict between the terms of this Agreement and the terms of a written agreement between You and Sway, the terms of the written agreement shall control.
        </p>
        <div class="section-title-container">
            <div class="title">ADDITIONAL TERMS</div>
        </div>
        <p>
            This Agreement, together with the Additional Terms, as they might change from time-to-time, shall constitute the entire agreement (collectively the <strong>Agreement</strong>).
        </p>
        <div class="section-title-container">
            <div class="title">NOT MEDICAL ADVICE</div>
        </div>
        <p>
            The Software and the Site, including any data collected while using the Software, the results and analyses thereof, and materials appearing on the Site, are provided to You for informational purposes only and do not constitute medical advice. If You believe You or Your test subject have suffered a concussion or are affected by some other medical condition, whether or not the Software test results support this belief, You should immediately stop using the Software and seek professional medical advice or consult with a qualified medical professional.
        </p>
        <div class="section-title-container">
            <div class="title">DISCLAIMER OF WARRANTIES</div>
        </div>
        <p>
            THE SOFTWARE AND THE SITE ARE COPYRIGHTED AND LICENSED (NOT SOLD). THIS AGREEMENT REPRESENTS THE ENTIRE AGREEMENT CONCERNING THE SOFTWARE BETWEEN YOU AND SWAY, AND IT SUPERSEDES ANY PRIOR PROPOSAL, REPRESENTATION, OR UNDERSTANDING BETWEEN THE PARTIES.
        </p>
        <p>
            THE SOFTWARE IS LICENSED “WHERE IS” AND “AS IS,” AND SWAY DISCLAIMS ANY AND ALL WARRANTIES TO THE EXTENT PERMITTED BY LAW, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES AS TO THE ACCURACY, RELIABILITY, COMPLETENESS, OR CORRECTNESS OF THE SOFTWARE, AND OF ANY TEST RESULTS.
        </p>
        <p>
            TO THE EXTENT PERMITTED BY LAW, SWAY DISCLAIMS, AMONG OTHERS, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND ALL WARRANTIES ARISING FORM COURSE OF DEALING OR USAGE OF TRADE.
        </p>
        <p>
            YOU ACKNOWLEDGE THAT THERE ARE RISKS INHERENT IN THE USE OF THE SOFTWARE, HARDWARE AND INTERNET CONNECTIVITY THAT MAY RESULT IN INTERRUPTIONS OR ERRORS IN THE SERVICE, LOSS OF PRIVACY, CONFIDENTIALITY, INFORMATION OR PROPERTY, AS WELL AS OTHER LOSSES, DAMAGES OR ADVERSE OCCURRENCES, REGARDLESS OF THE MEASURES TAKEN TO AVOID SUCH OCCURRENCES.
        </p>
        <p>
            <strong>IN NO EVENT WILL SWAY BE LIABLE TO YOU OR ANY THIRD PARTY IN CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES ARISING OUT OF THE USE OF OR INABILITY TO USE THE SOFTWARE, INACCURATE OR INCOMPLETE TEST RESULTS, LOST PROFITS OR DAMAGES EXTENDING FROM THE LOSS OR CORRUPTION OF DATA, AND CLAIMS RELATED TO TEST RESULTS, EVEN IF SWAY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</strong>
        </p>
        <p>
            SOME STATES DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
        </p>
        <!-- Add additional sections in the same structured format -->
    </div>
</template>

<script>
export default {
    name: "Terms"
};
</script>

<style scoped>
a {
    color: #34d3fe;
}
.section-title-container {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 10px;
}

.title {
    text-transform: uppercase;
    font-weight: bold;
}

.terms-container {
    color: #757575;
    font-size: 12px;
}
</style>
