<template>
    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;"
         class="about-container">
        <div style="max-height: 50px; margin: 20px" class="animate">
            <img :src="swayLogo" style="max-width: 100%; max-height: 50px">
        </div>
        <div style="margin: 20px; font-size: 16px; font-weight: bold">
            Version {{ version }}
        </div>
        <div style="margin-top: 20px">
            Choose your country
        </div>
        <div class="mobile-btn" style="margin-top: 10px" @click="selectedCountry = 1">
            USA / Canada
        </div>
        <div class="mobile-btn" style="margin-top: 10px" @click="selectedCountry = 2">
            Europe / Australia
        </div>
        <div v-if="selectedCountry" style="margin-top: 30px">
            <div >
                {{productionInformationTitle}}
            </div>
            <div >
                <div class="product-section-container" v-if="selectedCountry === 1">
                    <div class="product-info-logo">
                        <span style="font-size: 12px">UDI:</span>
                    </div>
                    <div class="product-info-text">
                        00868168000306
                    </div>
                </div>
                <div class="product-section-container">
                    <div class="product-info-logo">
                        <div class="product-img" >
                            <svg width="30px" height="24px" viewBox="0 0 56 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <!-- Generator: Sketch 51.1 (57501) - http://www.bohemiancoding.com/sketch -->
                                <title>Path Copy</title>
                                <desc>Created with Sketch.</desc>
                                <defs></defs>
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <polygon id="Path-Copy" stroke="#000000" stroke-width="2" fill="#000000" fill-rule="nonzero" points="1 48.8492124 1 28.0427002 7.38687518 18.1348372 13.5448897 27.4615285 20.0368215 18.1348372 26.5517454 28.0427002 34.5098711 18.1348372 41.1206548 28.0427002 41.1206548 1 54.0528744 1 54.0528744 48.8492124"></polygon>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div class="product-info-text">
                        <div>
                            Sway Medical
                        </div>
                        <div>
                            32 S. Lewis Ave.
                        </div>
                        <div>
                            Tulsa, OK 74101
                        </div>
                    </div>
                </div>
                <div class="product-section-container">
                    <div class="product-info-logo">
                        <img class="product-img" :src="images.ref" alt="">
                    </div>
                    <div class="product-info-text">
                        <div>
                            Sway Balance&trade; System
                        </div>

                    </div>
                </div>
                <div class="product-section-container">
                    <div class="product-info-logo">
                        <div class="product-img" >
                            <svg width="30px" height="24px" viewBox="0 0 56 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <!-- Generator: Sketch 51.1 (57501) - http://www.bohemiancoding.com/sketch -->
                                <title>Path Copy</title>
                                <desc>Created with Sketch.</desc>
                                <defs></defs>
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <polygon id="Path" stroke="#000000" stroke-width="2" fill-rule="nonzero" points="1 48.8492124 1 28.0427002 7.38687518 18.1348372 13.5448897 27.4615285 20.0368215 18.1348372 26.5517454 28.0427002 34.5098711 18.1348372 41.1206548 28.0427002 41.1206548 1 54.0528744 1 54.0528744 48.8492124"></polygon>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div class="product-info-text">
                        <div>
                            21 June 2024
                        </div>

                    </div>
                </div>
                <div class="product-section-container">
                    <div class="product-info-logo">
                        <img class="product-img" :src="images.rxOnly" alt="">
                    </div>
                    <div class="product-info-text">
                        <div>
                            Prescription Only
                        </div>

                    </div>
                </div>
                <div class="product-section-container">
                    <div class="product-info-logo">
                        <img class="product-img" :src="images.instructions" alt="">
                    </div>
                    <div class="product-info-text">
                        <div>
                            Consult Instructions for Use or User Manual
                        </div>
                    </div>
                </div>
                <div class="product-section-container">
                    <div class="product-info-logo">
                        <img class="product-img" :src="images.warning" alt="">
                    </div>
                    <div class="product-info-text">
                        <div>
                            See Warnings and Contraindications
                        </div>

                    </div>
                </div>
                <div v-if="selectedCountry === 2">
                    <div class="product-section-container">
                        <div class="product-info-logo">
                            <img class="product-img" :src="images.ecRep" alt="">
                        </div>
                        <div class="product-info-text">
                            <div>
                                CMC Medical Devices & Drugs S.L.
                            </div>
                            <div>
                                C/Horacio Lengo N° 18
                            </div>
                            <div>
                                CP 29006, Málaga Spain
                            </div>

                        </div>
                    </div>
                    <div class="product-section-container">
                        <div class="product-info-logo">
                            <div style="font-size: 10px; text-align: center">Australian Sponsor:</div>
                        </div>
                        <div class="product-info-text">
                            <div>
                                PlexusRA
                            </div>
                            <div>
                                3 Oxford Drive, Thurgoona
                            </div>
                            <div>
                                NSW 2640 Australia
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div >
                <div v-for="(section, index) in sections" :key="index">
                    <div class="section-title">
                        {{section.label}}
                    </div>
                    <div class="section-content">
                        {{section.text}}
                    </div>
                    <div class="section-content" v-if="selectedCountry === 1 && index === 2">
                        User Manual can be accessed at <a href="https://swaymedical.com/resources/videos">swaymedical.com</a>
                    </div>
                    <div v-if="section.component" style="margin-top: 20px">
                        <component :is="section.component" :selectedCountry="selectedCountry"/>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
// reference  https://mobilecontent.swaymedical.com/about/
import swayLogo from '@/assets/images/sway-logo.png'
import Help from "@/views/Help";
import AboutContraindications from "@/components/AboutContraindications";
import AboutWarnings from "@/components/AboutWarnings";

import ecRep from '@/assets/images/about/ec-rep.png'
import industry from '@/assets/images/about/industry.png'
import instructions from '@/assets/images/about/instructions.png'
import ref from '@/assets/images/about/ref.png'
import rxOnly from '@/assets/images/about/rx-only.png'
import warning from '@/assets/images/about/warning.png'

export default {
    name: "About",
    components: {
        Help,
        AboutContraindications,
        AboutWarnings
    },
    data() {
        return {
            images: {
                ecRep,
                industry,
                instructions,
                rxOnly,
                warning,
                ref
            },
            swayLogo,
            selectedCountry: null,

        }
    },
    computed: {
        version() {
            return this.$route.query.v ? this.$route.query.v : '-'
        },
        productionInformationTitle() {
            if (!this.selectedCountry) return null
            switch (this.selectedCountry) {
                case 1:
                    return `US / Canada Product \n Information`;
                case 2:
                    return 'Europe / Australia Product Information';
            }
        },
        sections() {
            if (!this.selectedCountry) return null
            switch (this.selectedCountry) {
                case 1:
                    return [
                        {
                            label: `1. Intended/Indications for Use`,
                            text: `The Sway Balance™ System is intended for use to assess sway as an indicator of balance. Individual suitability for assessment must be judged on a case by case basis, by a qualified individual including those certified and/or licensed in their state to prescribe and/or use balance devices such as certified athletic trainers and coaches, physical therapists, nurses and physicians. Conditions affecting postural sway include nausea, headache, orthopedic injury, ear infection, medications, head injury, dehydration and fatigue. The Sway Balance™ System can be used wherever an iOS mobile operating device is available.The Sway Cognitive System is intended for use as a computerized cognitive assessment aid to be administered by a qualified health profession to assess cognitive function. The Sway Cognitive software application is classified as a Class II exempt device, FDA product code PKQ.`
                        },
                        {
                            label: `2. Device Description`,
                            text: `The Sway Balance™ System is a mobile measurement system that analyzes balance through thoracic sway, using the built-in accelerometer of a mobile device. The Sway Balance™ System is a stand-alone mobile operating system software application that does not include any peripheral hardware add-ons.`
                        },
                        {
                            label: `3. Instructions for Use`,
                            text: ``,
                            component: Help

                        },
                        {
                            label: `4. Contraindications`,
                            text: ``,
                            component: AboutContraindications

                        },
                        {
                            label: `5. Warnings and Precautions`,
                            text: ``,
                            component: AboutWarnings

                        },

                    ];
                case 2:
                    return [
                        {
                            label: `1. Intended Use`,
                            text: `The Sway Balance™ System is intended for use by a licensed medical professional or trained coach, under the guidance of a licensed medical professional, to assess postural sway and reaction time. Individual suitability for assessment must be judged on a case by case basis by a qualified medical professional.`
                        },
                        {
                            label: `2. Indications for Use`,
                            text: `Conditions affecting postural sway and reaction time include nausea, headache, orthopedic injury, ear infection, pharmacologic effect, head injury, dehydration and fatigue.  The Sway Balance™ System can be used wherever an approved mobile device is available.

`
                        },
                        {
                            label: `3. Device Description`,
                            text: `The Sway Balance™ System is a software application tool that assesses balance and reaction time using the motion sensors in a mobile device (smart phone or tablet) without the need for additional hardware or plug-ins.  The software utilizes the built in hardware of the mobile device to provide an assessment of balance and reaction time, which can be compared to a baseline. The Sway Balance™ System can be used wherever an approved mobile device is available.

                                    The Sway Balance™ System protocols measure postural sway by having the user press the device against their chest while performing progressively more difficult balance conditions. Reaction time is assessed by analyzing responses to visual stimuli. A Sway Balance™ System score from 1-100 is generated using a proprietary algorithm.`,

                        },
                        {
                            label: `4. Instructions for Use`,
                            component: Help
                        },
                        {
                            label: `5. Contraindications`,
                            text: ``,
                            component: AboutContraindications

                        },
                        {
                            label: `6. Precautions`,
                            text: ``,
                            component: AboutWarnings

                        },


                    ];
            }
        }

    }
}
</script>

<style scoped>
.about-container {
    color: #757575;
    font-size: 12px
}
.mobile-btn {
    background-color: #34d3fe;
    padding: 10px 30px;
    min-width: 50%;
    color: white;
    font-weight: 600;
    text-align: center;
    border-radius: 5px;
    font-size: 16px
}

.product-info-text {
    font-size: 12px;

}

.product-section-container {
    display: flex;
    margin: 10px;
    align-items: center;
}

.mobile-btn:hover {
    cursor: pointer;
}

.product-info-logo {
    width: 70px;
    display: flex;
    justify-content: center;
}

.product-img {
    max-width: 80%;
    max-height: 30px;
}

.section-title {
    font-weight: bold;
    margin: 10px 0
}

.section-content {
    font-size: 11px;
    color: #757575
}
</style>